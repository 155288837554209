.web_listing_card_wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    height: 500px;
    overflow-y: scroll;
}
.website_po_cards{
    width: 270px !important;
    border-radius: 10px !important;
    /* max-height: 400px;
    min-height: 250px; */
    height: 100px !important;
    cursor: pointer;
}
.website_po_cards div{
    padding: 0;
}
.web_listing_card_wrapper .ant-card .ant-card-cover{
    height: 170px;
    overflow: hidden;
    border-radius: 15px 15px 0 0;
}
/* .website_po_card_icon{
    position: absolute;
    right: 15px;
    font-size: 20px;
} */
.web_select_modal_head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.website_po_card_pre{
    width: 290px;
    border-radius: 15px;
    max-height: 400px;
    min-height: 300px;
    padding: 0;
}
.website_po_card_pre_wrapper .ant-card .ant-card-cover{
    height: 150px;
    overflow: hidden;
    border-radius: 15px 15px 0 0;
}
.website_po_card_pre_wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.added-students-list{
    max-height: 600px;
    overflow: auto;
}
.card-content-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .card-image {
    width: 100px;
    height: 100%;
    flex-shrink: 0;
  }

  .card-details {
    flex-grow: 1;
    padding-left: 16px; 
    position: relative;
  }

  .website_po_card_icon {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 18px;
  }
  .card-details {
    flex-grow: 1;
    padding-left: 16px;
    overflow: hidden;
  }
  

  .card-details .ant-card-meta-title {
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    margin-bottom: 4px; 
  }
  

  .card-details .ant-card-meta-description {
    font-size: 12px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
  }
  .student_headder_action{
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-end;
  }

  .student-select-wrapper .ant-row .ant-form-item-label label {
    width: auto !important;
  }